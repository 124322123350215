import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import LocalizedLink from "../LocalizedLink";
import Offerings from "../Offerings";
import { RichMessage } from "../RichMessage";
import SEO from "../SEO";

const HomePageTemplate = ({ offerings }) => {
  const intl = useIntl();
  return (
    <div className="home">
      <SEO
        title={intl.formatMessage({ id: "home_page_title" })}
        description={intl.formatMessage({ id: "home_page_description" })}
      />
      <section className="hero is-primary is-bold is-medium">
        <div className="hero-body home-hero">
          <div className="container">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="section">
                  <h1 className="title">
                    <FormattedMessage id="hero_title" />
                  </h1>
                  <LocalizedLink
                    className="button is-primary is-outlined"
                    to="/contact"
                  >
                    <p className="bolded-button">
                      <FormattedMessage id="get_a_quote" />
                    </p>
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content">
                  <h2 className="has-text-weight-semibold is-size-2">
                    <FormattedMessage id="why_work_with_us" />
                  </h2>
                  <Offerings isProducts={false} gridItems={offerings.blurbs} />
                  <h1>
                    <FormattedMessage id="home_h1" />
                  </h1>
                  <p>
                    <RichMessage id="home_p1" />
                  </p>
                  <h2>
                    <FormattedMessage id="our_products" />
                  </h2>
                  <p>
                    <RichMessage id="our_products_description1" />
                  </p>
                  <ul>
                    <li>
                      <FormattedMessage id="marble" />
                    </li>
                    <li>
                      <FormattedMessage id="granite" />
                    </li>
                    <li>
                      <FormattedMessage id="quartz" />
                    </li>
                    <li>
                      <FormattedMessage id="quartzite" />
                    </li>
                  </ul>
                  <p>
                    <RichMessage id="our_products_description2" />
                  </p>
                  <h2>
                    <FormattedMessage id="installation_service" />
                  </h2>
                  <p>
                    <RichMessage id="installation_service_description" />
                  </p>
                  <h2>
                    <FormattedMessage id="types_of_countertops" />
                  </h2>
                  <p>
                    <RichMessage id="types_of_countertops_description" />
                  </p>
                  <ul>
                    <li>
                      <FormattedMessage id="kitchen" />
                    </li>
                    <li>
                      <FormattedMessage id="bar" />
                    </li>
                    <li>
                      <FormattedMessage id="fireplace" />
                    </li>
                    <li>
                      <FormattedMessage id="bathroom" />
                    </li>
                  </ul>
                  <p>
                    <RichMessage id="we_love_bookmatching" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

export default HomePageTemplate;
